import { gql } from 'urql'

const QueryCompanyJobList = gql`
  query (
    $page: Int
    $limit: Int
    $search: String
    $companyId: Int
    $country: String
    $state: String
    $status: [JobStatus!]
    $hiringMemberIds: [Int!]
    $countryStateId: Int
    $tagIds: [Int!]
    $operator: String
  ) {
    jobsList(
      page: $page
      limit: $limit
      search: $search
      companyId: $companyId
      country: $country
      state: $state
      status: $status
      hiringMemberIds: $hiringMemberIds
      countryStateId: $countryStateId
      tagIds: $tagIds
      operator: $operator
    ) {
      collection {
        id
        title
        status
        jobReferable
        jobLocations {
          name
          address
          state
          city
          country
        }
        slug
        jobStages {
          id
          stageLabel
          clientShared
          stageTypeId
          index
          updateable
          stageGroup
        }
        department {
          name
        }
        industries {
          name
        }
        statistic {
          viewCount
          applicantsCount
          newApplicantCount
        }
        teamMembers {
          id
          avatarVariants
          email
          fullName
          defaultColour
          roles {
            name
          }
        }
        jobRecruiters {
          id
          responsibility
          user {
            email
            fullName
            defaultColour
            avatarVariants
          }
        }
        hiringManagers {
          id
          fullName
          avatarVariants
          email
          defaultColour
          roles {
            name
          }
        }
        recruiters {
          id
          fullName
          avatarVariants
          email
          defaultColour
          roles {
            name
          }
        }
        owner {
          id
          fullName
          avatarVariants
          email
          defaultColour
          roles {
            name
          }
        }
        status
        statusDescription
        createdAt
        tenant {
          slug
        }
        applicants {
          id
          jobId
          coverLetter
          job {
            status
            id
            title
            slug
            owner {
              id
              fullName
              email
            }
          }
          incoming
          createdBy {
            fullName
          }
        }
        headcount
        company {
          id
          name
        }
        tags {
          id
          name
        }
      }
      metadata {
        currentPage
        totalCount
      }
    }
  }
`

export default QueryCompanyJobList
