import Link from 'next/link'
import React, { FC, useState } from 'react'
import { ICONS_SOCIAL } from '~/core/ui/InlineEditing'
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger
} from '~/core/ui/Popover'
import { Tooltip } from '~/core/ui/Tooltip'
import { reorderLinksList } from '~/lib/features/candidates/utilities'

const SocialLinks: FC<{
  source: {
    [key: string]: string[]
  }
  maxItems?: number
  size?: 'xs' | '2xs'
}> = ({ source, maxItems = 2, size = 'xs' }) => {
  const [openMoreLinkPopup, setOpenMoreLinkPopup] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useState<boolean>(false)
  const { showing: showingLinks, hidden: hiddenLinks } = reorderLinksList(
    source || {},
    maxItems
  )
  const iconFontSize = { xs: 20, '2xs': 16 }[size]
  const iconSize = { xs: 'w-5 h-5', '2xs': 'w-4 h-4' }[size]
  const countFontSize = {
    xs: 'text-[7px] leading-[7px]',
    '2xs': 'text-[6px] leading-[6px]'
  }[size]
  const iconSpacing = { xs: 'space-x-2.5', '2xs': 'space-x-1.5' }[size]

  return (
    <div className={`flex items-center ${iconSpacing}`}>
      {showingLinks.length > 0 &&
        showingLinks.map((item, index) => (
          <div
            key={`social-link-${index}`}
            className={`inline-flex hover:cursor-pointer ${iconSize}`}>
            <Link href={item?.link} target="_blank">
              <Tooltip content={item?.link}>
                {ICONS_SOCIAL({
                  key: item?.social_logo,
                  size: iconFontSize
                })}
              </Tooltip>
            </Link>
          </div>
        ))}
      {hiddenLinks.length > 0 && (
        <Popover
          open={openMoreLinkPopup}
          onOpenChange={(value) => setOpenMoreLinkPopup(value)}>
          <PopoverTrigger asChild>
            <div
              className={`inline-flex items-center justify-center rounded-full bg-gray-200 text-gray-700 hover:cursor-pointer ${iconSize} ${countFontSize}`}>
              +{hiddenLinks.length}
            </div>
          </PopoverTrigger>

          <PopoverPortal>
            <PopoverContent
              align="start"
              sideOffset={10}
              className="w-[360px] p-3">
              {hiddenLinks.map((item, index) => (
                <div
                  key={`social-link-${index}`}
                  className="mb-3 flex items-center space-x-3 last:mb-0 hover:cursor-pointer">
                  <div className="flex-none">
                    {ICONS_SOCIAL({
                      key: item?.social_logo,
                      size: iconFontSize
                    })}
                  </div>

                  <Link
                    href={item?.link}
                    target="_blank"
                    className="truncate break-all text-sm text-gray-900 hover:underline">
                    {item?.link}
                  </Link>
                </div>
              ))}
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      )}
    </div>
  )
}

export default SocialLinks
