import Link from 'next/link'
import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import { createContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import withPermissionFeatureProvider from 'src/hoc/with-permission-feature'
import withQueryClientProvider from 'src/hoc/with-query-client-provider'
import useSwitchRole from 'src/hooks/authorization/use-switch-role'
import useEnumsData from 'src/hooks/data/use-enums-data'
import Filter from '~/components/Filter/Filter'
import JobCard, { IJobCard } from '~/components/Jobs/Listing/JobCard'
import { withLayoutGrid } from '~/components/Layout/LayoutGrid'
import OnlyFor from '~/components/Security/OnlyFor'
import SkeletonContainer from '~/components/Skeleton'
import { useClassBasedTopSpace } from '~/components/Subscription/TopSpace'
import useSubscriptionPlan from '~/components/Subscription/useSubscriptionPlan'
import configuration from '~/configuration'
import {
  SESSION_COOKIE_CURRENT_TENANT_EXT,
  SESSION_COOKIE_EXT,
  SESSION_COOKIE_NAME,
  SESSION_COOKIE_ROLE_EXT
} from '~/core/constants/cookies'
import { AGENCY_TENANT } from '~/core/constants/enum'
import { ROLE_CODE } from '~/core/constants/role'
import { Badge } from '~/core/ui/Badge'
import { Button } from '~/core/ui/Button'
import Empty from '~/core/ui/Empty'
import If from '~/core/ui/If'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { ISelectOption } from '~/core/ui/Select'
import { Skeleton } from '~/core/ui/Skeleton'
import { Tabs, TabsList, TabsTrigger, TabsTriggerView } from '~/core/ui/Tabs'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import { sendChromeRuntimeMessage } from '~/core/utilities/common'
import {
  ACTIONS_PERMISSIONS,
  canAccessFeature,
  DEFAULT_PERMISSIONS_LIST,
  IDefaultPermissionsList,
  PERMISSIONS_LIST
} from '~/core/utilities/feature-permission'
import { isBrowser } from '~/core/utilities/is-browser'
import QueryAgencyLocations from '~/lib/features/agency/companies/graphql/query-location-agency-list'
import { useQueryCareerSetting } from '~/lib/features/calendar/hooks/use-query-career-setting'
import useCareerSettingStore from '~/lib/features/careers/[id]/store'
import useJobManagement from '~/lib/features/jobs/hooks/use-job-management'
import { renderCombineText } from '~/lib/features/jobs/utilities/common'
import { JOB_STATUS_OPTION_ROLE_MAP } from '~/lib/features/jobs/utilities/enum'
import usePermissionJob from '~/lib/features/permissions/hooks/use-permission-job'
import QueryTenantDepartment from '~/lib/features/settings/departments/graphql/query-tenant-department'
import { IDepartment } from '~/lib/features/settings/departments/types'
import QueryCompanyListOptions from '~/lib/features/settings/locations/graphql/query-company-list-options'
import QueryPublicLocation from '~/lib/features/settings/locations/graphql/query-public-location'
import QueryHiringMembers from '~/lib/features/settings/members/graphql/query-member-filter'
import { PLAN_FEATURE_KEYS } from '~/lib/features/settings/plans/utilities/enum'
import useReferralSetting from '~/lib/features/settings/referrals/hooks/useReferralSetting'
import QueryTagsList from '~/lib/features/settings/tags/graphql/query-tags-list'
import { TAG_KIND } from '~/lib/features/settings/tags/utilities/enum'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import useBoundStore from '~/lib/store'
import BulkActions from './BulkActions'
import { IDataTableInfinityResponse } from '~/core/ui/TableInfinityOrdering'
import { cn } from '~/core/ui/utils'

export const JobManagementPermissionContext =
  createContext<IDefaultPermissionsList>(DEFAULT_PERMISSIONS_LIST)

const JobsManagementContainer = () => {
  const cookies = parseCookies()
  const { isCompanyKind, isLoaded } = useDetectCompanyWithKind({
    kind: AGENCY_TENANT
  })
  const { careerPageSetting, setCareerPageSetting } = useCareerSettingStore()
  const { jobPaging, filterControl, action } = useJobManagement({
    isCompanyKind,
    isLoaded
  })
  const {
    isFeatureEnabled,
    isUnLockFeature,
    data: dataTenantSettingPlan
  } = useSubscriptionPlan()

  const { dataReferral } = useReferralSetting({
    suspend:
      !dataTenantSettingPlan ||
      !isFeatureEnabled(PLAN_FEATURE_KEYS.referral) ||
      !isUnLockFeature(PLAN_FEATURE_KEYS.referral)
  })

  const { data: careerResponseSetting, trigger: triggerCareerSetting } =
    useQueryCareerSetting({
      variables: {},
      shouldPause: true
    })
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const switchRole = useSwitchRole()
  const {
    user,
    currentRole,
    bulkSelectedAll,
    bulkValues,
    refetchMyList,
    setRefetchMyList
  } = useBoundStore()

  const { actionJob } = usePermissionJob()
  const jobStatus = useEnumsData({
    enumType: 'JobStatus',
    locale: i18n.language
  })

  const [extensionCallback, setExtensionCallback] = useState(true)

  const calcHeight = useClassBasedTopSpace({
    34: 'h-[calc(100vh_-_90px)]',
    default: 'h-[calc(100vh_-_56px)]'
  })

  useEffect(() => {
    if (isBrowser() && extensionCallback) {
      setExtensionCallback(false)
      const params = new URL(String(window.location)).searchParams
      const returnUrl = params.get('returnUrl')

      if (returnUrl && returnUrl.startsWith('/ext')) {
        sendChromeRuntimeMessage({
          [SESSION_COOKIE_EXT]: cookies[SESSION_COOKIE_NAME],
          [SESSION_COOKIE_CURRENT_TENANT_EXT]: JSON.stringify(
            cookies[SESSION_COOKIE_CURRENT_TENANT_EXT]
          ).toString(),
          [SESSION_COOKIE_ROLE_EXT]: JSON.stringify(
            cookies[SESSION_COOKIE_ROLE_EXT]
          ).toString()
        }).then(() => {
          router.replace(configuration.path.default)
        })
      }
    }
  }, [cookies, extensionCallback, router])

  useEffect(() => {
    if (!careerPageSetting) triggerCareerSetting()
  }, [careerPageSetting])

  useEffect(() => {
    if (!!careerResponseSetting) setCareerPageSetting(careerResponseSetting)
  }, [careerResponseSetting])

  useEffect(() => {
    if (refetchMyList) {
      jobPaging.refetch()
      setRefetchMyList(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchMyList])

  return (
    <JobManagementPermissionContext.Provider value={actionJob}>
      <div className="bg-gray-50">
        <div className="flex h-[56px] items-center justify-between border-b border-b-gray-100 bg-white px-6 py-[15px]">
          <div className="flex flex-1 items-center">
            <TypographyText className="mr-2 text-lg font-medium text-gray-900">
              {t('job:listing:jobTitle')}
            </TypographyText>

            <Badge radius="circular">
              {jobPaging?.data?.pages?.[0]?.jobsList?.metadata?.totalCount || 0}
            </Badge>
          </div>

          <Filter
            value={filterControl.value}
            onChange={(filterVariable, name) => {
              if (name === 'operator') {
                if (filterVariable?.tagIds?.length) {
                  filterControl.onChange(filterVariable)
                }
              } else {
                filterControl.onChange(filterVariable)
              }
            }}>
            <div className="flex">
              <div className="mr-2">
                <Filter.SearchText
                  width={160}
                  typingDebounceSubmit={500}
                  size="xs"
                  placeholder={`${t('job:filter:placeholderInputSearch')}`}
                  name="search"
                />
              </div>
              <OnlyFor not={ROLE_CODE.LimitedMember}>
                {isCompanyKind === false ? (
                  <>
                    <div className="mr-2">
                      <Filter.Combobox
                        menuOptionAlign="end"
                        menuOptionSide="bottom"
                        isSearchable={false}
                        isMulti
                        buttonClassName="min-w-[81px] text-gray-900 max-w-[248px]"
                        buttonFontWeightClassName="font-normal"
                        dropdownMenuClassName="!w-[248px]"
                        size="sm"
                        countName={`${t('label:locations')}`}
                        optionsFromDocumentNode={{
                          documentNode: QueryPublicLocation,
                          variable: (searchParams) => ({
                            ...searchParams,
                            tenantSlug: user.currentTenant?.slug
                          }),
                          mapping: (data) => {
                            return {
                              metadata: data?.publicLocationsList.metadata,
                              collection:
                                data?.publicLocationsList.collection.map(
                                  (location) => ({
                                    id: location.countryStateId,
                                    value: String(location.countryStateId),
                                    label: location.country,
                                    supportingObj: {
                                      name: renderCombineText([
                                        location?.state,
                                        location?.country
                                      ])
                                    }
                                  })
                                )
                            }
                          }
                        }}
                        placeholder={
                          t('job:filter:placeholderSelectLocation') || ''
                        }
                        searchPlaceholder={`${t('label:placeholder:search')}`}
                        loadingMessage={`${t('label:loading')}`}
                        noOptionsMessage={`${t('label:noOptions')}`}
                        name="locationIds"
                      />
                    </div>

                    <div className="mr-2">
                      <Filter.Combobox
                        menuOptionAlign="end"
                        menuOptionSide="bottom"
                        isMulti
                        isSearchable
                        isMultiGroup={true}
                        buttonClassName="min-w-[100px] text-gray-900 max-w-[116px]"
                        buttonFontWeightClassName="font-normal"
                        dropdownMenuClassName="!w-[296px]"
                        optionsFromDocumentNode={{
                          documentNode: QueryTenantDepartment,
                          variable: (searchParams) => searchParams,
                          mapping: (data) => {
                            const cloneData =
                              data?.tenantDepartmentsList.collection.map(
                                (item: IDepartment) => {
                                  return {
                                    id: item.id,
                                    name: item.name,
                                    openJobsCount: item.openJobsCount,
                                    parentId: item.parentId,
                                    subordinates: item.subordinates
                                  }
                                }
                              )

                            const newCloneData = [] as Array<ISelectOption>
                            cloneData.forEach((item) => {
                              newCloneData.push({
                                value: String(item.id),
                                parentId: undefined,
                                supportingObj: {
                                  name: item.name || ''
                                }
                              })

                              if (item.subordinates?.length) {
                                item.subordinates.forEach((sub) => {
                                  newCloneData.push({
                                    value: String(sub.id),
                                    parentId: String(item.id),
                                    supportingObj: {
                                      name: sub.name || ''
                                    }
                                  })
                                })
                              }
                            })
                            return {
                              metadata: data.tenantDepartmentsList.metadata,
                              collection: newCloneData
                            }
                          }
                        }}
                        size="sm"
                        placeholder={
                          t('job:filter:placeholderSelectDepartment') || ''
                        }
                        searchPlaceholder={`${t('label:placeholder:search')}`}
                        loadingMessage={`${t('label:loading')}`}
                        noOptionsMessage={`${t('label:noOptions')}`}
                        name="departmentIds"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mr-2">
                      <Filter.Combobox
                        menuOptionAlign="end"
                        menuOptionSide="bottom"
                        isSearchable
                        buttonClassName="min-w-[81px] text-gray-900 max-w-[248px]"
                        buttonFontWeightClassName="font-normal"
                        dropdownMenuClassName="!w-[248px]"
                        containerMenuClassName="!max-w-[248px]"
                        size="sm"
                        countName={`${t('label:placeholder:companies')}`}
                        optionsFromDocumentNode={{
                          documentNode: QueryCompanyListOptions,
                          variable: (searchParams) => searchParams,
                          mapping: (data) => {
                            return {
                              metadata: data?.companiesList.metadata,
                              collection: data?.companiesList.collection.map(
                                (company: { id: number; name: string }) => ({
                                  value: company.id,
                                  supportingObj: {
                                    name: company.name
                                  }
                                })
                              )
                            }
                          }
                        }}
                        placeholder={`${t('label:placeholder:company')}`}
                        searchPlaceholder={`${t('label:placeholder:search')}`}
                        loadingMessage={`${t('label:loading')}`}
                        noOptionsMessage={`${t('label:noOptions')}`}
                        name="companyId"
                      />
                    </div>

                    <div className="mr-2">
                      <Filter.Combobox
                        menuOptionAlign="end"
                        menuOptionSide="bottom"
                        isSearchable
                        buttonClassName="min-w-[81px] text-gray-900 max-w-[248px]"
                        buttonFontWeightClassName="font-normal"
                        dropdownMenuClassName="!w-[248px]"
                        containerMenuClassName="!w-[248px]"
                        size="sm"
                        countName={`${t('label:locations')}`}
                        optionsFromDocumentNode={{
                          documentNode: QueryAgencyLocations,
                          variable: (searchParams) => ({
                            ...searchParams,
                            tenantSlug: user?.currentTenant?.slug
                          }),
                          mapping: (data) => {
                            return {
                              metadata:
                                data?.companyLocsByCountryStateList.metadata,
                              collection:
                                data?.companyLocsByCountryStateList.collection.map(
                                  (location: {
                                    id: number
                                    state: string
                                    country: string
                                    countryStateId?: number
                                  }) => ({
                                    id: location?.countryStateId,
                                    value: `${location.state}*${location.country}`,
                                    supportingObj: {
                                      name: `${location.state}, ${location.country}`
                                    }
                                  })
                                )
                            }
                          }
                        }}
                        placeholder={
                          t('job:filter:placeholderSelectLocation') || ''
                        }
                        searchPlaceholder={`${t('label:placeholder:search')}`}
                        loadingMessage={`${t('label:loading')}`}
                        noOptionsMessage={`${t('label:noOptions')}`}
                        name="location"
                      />
                    </div>
                  </>
                )}
              </OnlyFor>

              <div className="mr-2">
                <Filter.Combobox
                  isMulti
                  isSearchable={false}
                  buttonClassName="min-w-[100px] text-gray-900"
                  buttonFontWeightClassName="font-normal"
                  dropdownMenuClassName="!w-[156px]"
                  menuOptionAlign="end"
                  menuOptionSide="bottom"
                  countName={`${t('label:placeholder:status')}`}
                  options={JOB_STATUS_OPTION_ROLE_MAP({
                    currentRoleCode:
                      currentRole?.code || ROLE_CODE.LimitedMember,
                    jobStatus
                  })}
                  size="sm"
                  placeholder={t('job:filter:placeholderSelectStatus') || ''}
                  searchPlaceholder={`${t('label:placeholder:search')}`}
                  loadingMessage={`${t('label:loading')}`}
                  noOptionsMessage={`${t('label:noOptions')}`}
                  name="status"
                />
              </div>

              <OnlyFor not={ROLE_CODE.LimitedMember}>
                <div className="mr-2">
                  <Filter.Combobox
                    isMulti
                    dropdownMenuClassName="!w-[248px]"
                    containerMenuClassName="max-w-[248px]"
                    optionsFromDocumentNode={{
                      documentNode: QueryHiringMembers,
                      variable: (searchParams) => searchParams,
                      mapping: (data) => {
                        return {
                          metadata: data?.membersList.metadata,
                          collection: data?.membersList.collection.map(
                            (member) => ({
                              value: String(member.id),
                              avatar: member.avatarVariants?.thumb?.url,
                              avatarVariants: member.avatarVariants,
                              supportingObj: {
                                name: member.fullName,
                                defaultColour: member.defaultColour
                              }
                            })
                          )
                        }
                      }
                    }}
                    size="sm"
                    menuOptionAlign="end"
                    menuOptionSide="bottom"
                    avatarToolTipPosition="bottom"
                    toolTipPositionAvatarCount="bottom"
                    tooltipAlignAvatarCount="end"
                    placeholder={`${t('label:placeholder:hiring_member')}`}
                    searchPlaceholder={`${t('label:placeholder:search')}`}
                    loadingMessage={`${t('label:loading')}`}
                    noOptionsMessage={`${t('label:noOptions')}`}
                    name="hiringMemberIds"
                  />
                </div>
              </OnlyFor>
              <div className="mr-2">
                <Filter.Item<string> name="operator">
                  {({ value, onChange }) => {
                    return (
                      <Filter.Combobox
                        tooltipOption={{
                          position: 'bottom',
                          content: (
                            <>{`${t('tooltip:filter_by_tag_name', {
                              name: {
                                or: `${t('label:tag_any_match')}`,
                                and: `${t('label:tag_match_all')}`
                              }[value]
                            })}`}</>
                          )
                        }}
                        size="sm"
                        isSearchable={true}
                        isMulti
                        countName={`${t('label:placeholder:tags')}`}
                        menuOptionAlign="end"
                        menuOptionSide="bottom"
                        customerDropdownHeader={
                          <div className="pb-1 pl-3 pr-3 pt-2">
                            <Tabs
                              value={value}
                              onValueChange={(value) => {
                                onChange(value)
                              }}>
                              <TabsList
                                isFullWidth
                                size="xs"
                                typeTab="switch-tab">
                                <TabsTrigger
                                  isFullWidth
                                  className="flex"
                                  size="xs"
                                  typeTab="switch-tab"
                                  value="or">
                                  <TabsTriggerView
                                    session={{
                                      label: `${t('label:tab:any_match')}`,
                                      value: 'or'
                                    }}
                                    size="xs"
                                    typeTab="switch-tab"
                                  />
                                </TabsTrigger>
                                <TabsTrigger
                                  isFullWidth
                                  gapSize="md"
                                  size="xs"
                                  typeTab="switch-tab"
                                  value="and">
                                  <TabsTriggerView
                                    session={{
                                      label: `${t('label:tab:match_all')}`,
                                      value: 'and'
                                    }}
                                    size="xs"
                                    typeTab="switch-tab"
                                  />
                                </TabsTrigger>
                              </TabsList>
                            </Tabs>
                          </div>
                        }
                        optionsFromDocumentNode={{
                          documentNode: QueryTagsList,
                          variable: (searchParams) => ({
                            ...searchParams,
                            sortByAlphabet: 'true',
                            kind: TAG_KIND.job
                          }),
                          mapping: (data) => {
                            return {
                              metadata: data.tagsList.metadata,
                              collection: data?.tagsList.collection.map(
                                (tag) => ({
                                  value: String(tag.id),
                                  supportingObj: {
                                    name: tag.name
                                  }
                                })
                              )
                            }
                          }
                        }}
                        placeholder={`${t('label:placeholder:tag')}`}
                        searchPlaceholder={`${t('label:placeholder:search')}`}
                        loadingMessage={`${t('label:loading')}`}
                        noOptionsMessage={`${t('label:noOptions')}`}
                        name="tagIds"
                        dropdownMenuClassName="!w-[230px]"
                        buttonClassName="max-w-[160px]"
                        containerMenuClassName="!max-w-[230px]"
                      />
                    )
                  }}
                </Filter.Item>
              </div>
              <If condition={actionJob.create}>
                <div>
                  <Link href={configuration.path.jobs.create}>
                    <Button
                      htmlType="button"
                      size="xs"
                      iconMenus="Plus"
                      label={t('button:newJob') || ''}
                    />
                  </Link>
                </div>
              </If>
            </div>
          </Filter>
        </div>

        <ScrollArea className={calcHeight}>
          <div className="mx-auto w-full max-w-[1332px]">
            <SkeletonContainer
              showMoreLabel={`${t('common:infinity:showMore')}`}
              useLoading={false}
              isFirstLoading={jobPaging.data === undefined}
              renderCustomSkeleton={
                <div className="mx-6 mt-2 space-y-2 py-3">
                  {[1, 2, 3].map((item) => (
                    <div
                      key={`task-skeleton-${item}`}
                      className="flex justify-center">
                      <div className="flex h-[153px] w-full flex-col justify-center space-y-2 rounded-lg bg-white p-4">
                        <Skeleton className="h-4 w-2/5 rounded" />
                        <Skeleton className="h-4 w-full rounded" />
                        <Skeleton className="h-4 w-full rounded" />
                        <Skeleton className="h-4 w-2/3 rounded" />
                      </div>
                    </div>
                  ))}
                </div>
              }>
              <div
                className={cn(
                  'mx-6 pt-3',
                  bulkSelectedAll || bulkValues?.length ? 'pb-[75px]' : 'pb-6'
                )}>
                {jobPaging.data?.pages[0]?.jobsList?.metadata?.totalCount ===
                  0 &&
                  (!filterControl.value?.isFilterTouched ? (
                    <div
                      style={{ minHeight: 'calc(100vh - 170px)' }}
                      className="flex items-center">
                      <Empty
                        type="empty-data"
                        title={t('job:emptyContent:emptyTitle') || ''}
                        description={switchRole({
                          default:
                            t('job:emptyContent:emptyDescriptionDefault') || '',
                          limited_member:
                            t('job:emptyContent:emptyDescriptionInterview') ||
                            ''
                        })}
                        {...switchRole({
                          default: {
                            buttonTitle: t('button:createNewJob') || ''
                          },
                          limited_member: {}
                        })}
                        onClick={() => {
                          router.push(configuration.path.jobs.create)
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ minHeight: 'calc(100vh - 170px)' }}
                      className="flex items-center">
                      <Empty
                        type="empty-search"
                        title={
                          t('job:emptyContent:emptyTitleNoSearchFound') || ''
                        }
                        description={
                          t('job:emptyContent:emptyDescriptionNoSearchFound') ||
                          ''
                        }
                        buttonTitle={t('button:clearFilter') || ''}
                        onClick={() =>
                          filterControl.onChange({
                            operator: 'or'
                          })
                        }
                      />
                    </div>
                  ))}

                {jobPaging?.data?.pages?.map((page) =>
                  page?.jobsList?.collection?.map((job: IJobCard) => {
                    return (
                      <div key={job.id} className="mt-2">
                        <JobCard
                          enablingReferral={
                            dataReferral?.values?.enabling || false
                          }
                          isCompanyKind={isCompanyKind}
                          job={job}
                          deleteAction={{
                            executed: (id: number) => {
                              return action.jobDeleteAction.deleteJob({
                                id
                              })
                            },
                            loading: action.jobDeleteAction.deletingJob
                          }}
                          updateAction={{
                            executed: (id: number, status: string) => {
                              return action.changeJobStatusAction.changeJobStatus(
                                {
                                  id,
                                  status
                                }
                              )
                            },
                            loading:
                              action.changeJobStatusAction.changingJobStatus
                          }}
                          callback={() => jobPaging.refetch()}
                        />
                      </div>
                    )
                  })
                )}

                {jobPaging.hasNextPage ? (
                  <div className="mt-6 flex justify-center">
                    <TextButton
                      onClick={() => jobPaging.fetchNextPage()}
                      label={t('button:showMore') || ''}
                    />
                  </div>
                ) : (
                  (jobPaging?.data?.pages?.[0]?.jobsList?.metadata
                    ?.totalCount || 0) > 10 && (
                    <p className="mt-6 text-center text-sm text-gray-600 dark:text-gray-300">
                      {t('label:paging:labelEndOfList')}
                    </p>
                  )
                )}
              </div>
            </SkeletonContainer>
          </div>
        </ScrollArea>
      </div>
      <BulkActions jobs={jobPaging.data as IDataTableInfinityResponse} />
    </JobManagementPermissionContext.Provider>
  )
}

export default withPermissionFeatureProvider(
  {
    checkAccessPermission: canAccessFeature,
    keyModule: [PERMISSIONS_LIST.job_management.keyModule],
    keyModuleObject: [
      PERMISSIONS_LIST.job_management.objects.job.keyModuleObject
    ],
    action: ACTIONS_PERMISSIONS.show
  },
  withLayoutGrid(withQueryClientProvider(JobsManagementContainer))
)
