export const MutationUpdateStatuesJobs = `
  mutation (
    $jobIds: [Int!]
		$toStatus: JobStatus!
		$selectAll: Boolean
    $search: String
    $departmentIds: [Int!]
    $locationIds: [Int!]
    $countryStateIds: [Int!]
    $status: [JobStatus!]
    $hiringMemberIds: [Int!]
    $tagIds: [Int!]
    $operator: String
  ) {
    jobsUpdateStatuses(
			input: { 
				jobIds: $jobIds
				toStatus: $toStatus
				selectAll: $selectAll
        search: $search
      	departmentIds: $departmentIds
      	countryStateIds: $countryStateIds
      	locationIds: $locationIds
      	status: $status
      	hiringMemberIds: $hiringMemberIds
      	tagIds: $tagIds
      	operator: $operator
			}
		) {
      success
    }
  }
`
