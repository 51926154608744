import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '~/core/ui/Button'
import { Checkbox } from '~/core/ui/Checkbox'

const BottomActionBar: FC<{
  children: React.ReactNode
  isSelectedAll: boolean
  data: string[]
  onClose: () => void
  setSelectedAll?: (e: { target: { checked: boolean } }) => void
  onDelete?: (() => void) | null
}> = ({
  children,
  isSelectedAll,
  data,
  onClose = () => {},
  setSelectedAll = (e) => {},
  onDelete = null
}) => {
  const { t } = useTranslation()
  return (
    <div className="absolute bottom-0 left-1/2 z-10 mb-2 flex h-[48px] -translate-x-1/2 transform items-center justify-between rounded-md bg-white px-2 shadow-[2px_4px_20px_0px_rgba(0,0,0,0.15)]">
      <Checkbox
        isChecked={isSelectedAll}
        onCheckedChange={setSelectedAll}
        size="sm"
        className="mt-0"
      />
      <span className="mx-1 line-clamp-1 text-sm font-normal text-gray-600">
        {isSelectedAll
          ? t('common:allSelected')
          : t('common:selected', { numberCount: data.length })}
      </span>
      {children}
      {onDelete && (
        <Button
          size="sm"
          iconMenus="Trash2"
          type="destructive-outlined"
          onClick={onDelete}
          label={`${t('button:delete')}`}
          className="mx-1"
        />
      )}

      <Button
        size="sm"
        iconMenus="X"
        type="secondary-destructive"
        onClick={onClose}
        className="mx-1"
      />
    </div>
  )
}

export default BottomActionBar
