import React, { FC, useState, useCallback } from 'react'
import { Button } from '~/core/ui/Button'
import { Trans, useTranslation } from 'react-i18next'
import { Dialog } from '~/core/ui/Dialog'
import { Controller } from 'react-hook-form'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { z } from 'zod'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { JOB_STATUS_ENUM } from '~/lib/features/jobs/utilities/enum'
import { ISelectOption } from '~/core/ui/Select'
import { openAlert } from '~/core/ui/AlertDialog'
import useBoundStore from '~/lib/store'
import { schemaUdpateStatusJob } from '~/lib/features/jobs/schema/validation-status-job'

type StatusJobForm = {
  status: ISelectOption
}

const StatusSelector: FC<{
  onSubmit: (val: StatusJobForm) => Promise<void>
  totalCount?: Number
}> = ({ onSubmit, totalCount }) => {
  const { bulkSelectedAll, bulkValues } = useBoundStore()

  const { t } = useTranslation()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const defaultStatus = {
    status: {
      value: JOB_STATUS_ENUM.publish,
      dot: 'green',
      supportingObj: {
        name: t('label:job_status:publish'),
        description: t('label:optionJobStatus:publish') || ''
      }
    } as ISelectOption
  }

  const handleSubmit = async (data: StatusJobForm) => {
    if (data.status.value === JOB_STATUS_ENUM.publish) {
      setOpenDialog(false)
      onSubmit(data)
    } else {
      return openAlert({
        isPreventAutoFocusDialog: false,
        className: 'w-[480px]',
        title: `${t('common:modal:change_job_status_title')}`,
        description: (
          <Trans
            i18nKey={`job:changeStatusJobs:${data.status.value}`}
            values={{
              number: bulkSelectedAll ? totalCount : bulkValues?.length
            }}>
            <span className="font-medium text-gray-900" />
          </Trans>
        ),
        actions: [
          {
            label: `${t('button:cancel')}`,
            type: 'secondary',
            size: 'sm'
          },
          {
            isCallAPI: true,
            label: `${t('button:changeStatusAnyway')}`,
            type: 'destructive',
            size: 'sm',
            onClick: () => {
              setOpenDialog(false)
              onSubmit(data)
            }
          }
        ]
      })
    }
  }

  return (
    <>
      <Button
        size="sm"
        iconMenus="Disc"
        type="secondary"
        onClick={() => {
          setOpenDialog(true)
        }}
        label={`${t('button:changeStatus')}`}
        className="mx-1"
      />
      <Dialog
        open={openDialog}
        size="sm"
        onOpenChange={setOpenDialog}
        isPreventAutoFocusDialog={true}
        label={`${t('common:modal:change_job_status_title')}`}>
        <DynamicImportForm
          isShowDebug={false}
          className="w-full"
          schema={schemaUdpateStatusJob()}
          defaultValue={defaultStatus}
          onSubmit={handleSubmit}>
          {({ formState, control }) => {
            return (
              <>
                <Controller
                  control={control}
                  name="status"
                  defaultValue={defaultStatus.status}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlItem
                        destructive={
                          formState.errors && !!formState.errors?.status
                        }
                        destructiveText={
                          formState.errors &&
                          (formState.errors?.status?.message as string)
                        }>
                        <NativeSelect
                          size="md"
                          onChange={(newValue) => {
                            onChange(newValue)
                          }}
                          placeholder={`${t('label:placeholder:select')}`}
                          value={value}
                          configSelectOption={{
                            supportingText: ['description'],
                            dot: true
                          }}
                          isClearable={false}
                          options={[
                            {
                              value: JOB_STATUS_ENUM.publish,
                              dot: 'green',
                              supportingObj: {
                                name: t('label:job_status:publish'),
                                description:
                                  t('label:optionJobStatus:publish') || ''
                              }
                            },
                            {
                              value: JOB_STATUS_ENUM.internal,
                              dot: 'purple',
                              supportingObj: {
                                name: t('label:job_status:internal'),
                                description:
                                  t('label:optionJobStatus:internal') || ''
                              }
                            },
                            {
                              value: JOB_STATUS_ENUM.archived,
                              dot: 'red',
                              supportingObj: {
                                name: t('label:job_status:archived'),
                                description:
                                  t('label:optionJobStatus:archived') || ''
                              }
                            }
                          ]}
                        />
                      </FormControlItem>
                    )
                  }}
                />
                <div className="flex items-center justify-end pt-6">
                  <Button
                    type="secondary"
                    className="mr-3"
                    size="sm"
                    onClick={() => setOpenDialog(false)}
                    label={`${t('button:cancel')}`}
                  />
                  <Button
                    isDisabled={false}
                    isLoading={false}
                    size="sm"
                    htmlType="submit"
                    label={`${t('button:update')}`}
                  />
                </div>
              </>
            )
          }}
        </DynamicImportForm>
      </Dialog>
    </>
  )
}

export default StatusSelector
