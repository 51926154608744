import React, { FC, memo, useEffect, useCallback } from 'react'
import BottomActionBar from '~/components/BottomActionBar'
import StatusSelector from './StatusSelector'
import useBoundStore from '~/lib/store'
import { IJobCard } from '~/components/Jobs/Listing/JobCard'
import { MutationDeletesJobs } from '~/lib/features/jobs/graphql/mutation-delete-jobs'
import { AgencyMutationDeletesJobs } from '~/lib/features/jobs/graphql/agency-mutation-delete-jobs'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import { mappingFilterJob } from '~/lib/features/jobs/mapping/job-filter-mapping'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { useTranslation, Trans } from 'react-i18next'
import { openAlert } from '~/core/ui/AlertDialog'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { AGENCY_TENANT } from '~/core/constants/enum'
import { adminCanAction } from '~/core/utilities/permission'
import { ISelectOption } from '~/core/ui/Select'
import { MutationUpdateStatuesJobs } from '~/lib/features/jobs/graphql/mutation-update-statues-jobs'
import { AgencyMutationUpdateStatuesJobs } from '~/lib/features/jobs/graphql/agency-update-statues-jobs'
import {
  IDataTableInfinityResponse,
  IDataPageResponse
} from '~/core/ui/TableInfinityOrdering'

type StatusJobForm = {
  status: ISelectOption
}

const BulkActions: FC<{ jobs: IDataTableInfinityResponse }> = ({
  jobs = { pages: [] }
}) => {
  const {
    bulkSelectedAll,
    bulkValues,
    resetBulkValues,
    setBulkSelectedAll,
    setBulkValues,
    setShowLockApp,
    filterValues,
    setCloseLockApp,
    setToast,
    setRefetchMyList,
    currentRole
  } = useBoundStore()

  const totalCount = jobs?.pages?.[0]?.jobsList?.metadata?.totalCount || 0

  const { isCompanyKind: isAgencyCompany } = useDetectCompanyWithKind({
    kind: AGENCY_TENANT
  })

  const { t } = useTranslation()
  const { trigger: deleteJobs, isLoading: isLoadingDeleteJobs } =
    useSubmitCommon(
      isAgencyCompany ? AgencyMutationDeletesJobs : MutationDeletesJobs
    )

  const { trigger: updateStatuesJobs } = useSubmitCommon(
    isAgencyCompany
      ? AgencyMutationUpdateStatuesJobs
      : MutationUpdateStatuesJobs
  )

  const handleClose = () => {
    resetBulkValues()
  }

  const handleCheckAll = (e: { target: { checked: boolean } }) => {
    const { checked } = e.target
    setBulkSelectedAll(checked)
    if (checked) setIds()
    else resetBulkValues()
  }

  const setIds = () => {
    let ids: string[] = []
    jobs.pages.map((page: IDataPageResponse) => {
      page?.jobsList?.collection?.map((job: IJobCard) => {
        ids = [...ids, job.id]
      })
    })
    setBulkValues(ids)
  }

  const onDeleteJobs = useCallback(async () => {
    setShowLockApp('')
    const params = mappingFilterJob(1, filterValues)
    if (isLoadingDeleteJobs) {
      return
    }

    deleteJobs({
      jobIds: bulkValues?.map((id) => Number(id)),
      selectAll: bulkSelectedAll,
      ...params
    }).then((result) => {
      if (result.error) {
        return catchErrorFromGraphQL({
          error: result.error,
          setToast
        })
      }
      const { jobsDeletes } = result.data
      if (jobsDeletes.success) {
        setCloseLockApp()
        setRefetchMyList(true)
        resetBulkValues()
        setToast({
          open: true,
          type: 'success',
          title:
            bulkValues && bulkValues?.length > 1
              ? t('notification:jobs:jobCard:deleteJobs')
              : t('notification:jobs:jobCard:deleteJob')
        })
      }
      return true
    })
  }, [bulkValues])

  const onUpdateStatuesJobs = useCallback(
    async (data: StatusJobForm) => {
      setShowLockApp('')
      const params = mappingFilterJob(1, filterValues)
      if (isLoadingDeleteJobs) {
        return
      }

      updateStatuesJobs({
        jobIds: bulkValues?.map((id) => Number(id)),
        selectAll: bulkSelectedAll,
        toStatus: data.status.value,
        ...params
      }).then((result) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            setToast
          })
        }
        const { jobsUpdateStatuses } = result.data
        if (jobsUpdateStatuses.success) {
          setCloseLockApp()
          setRefetchMyList(true)
          resetBulkValues()
          setToast({
            open: true,
            type: 'success',
            title: t('notification:jobs:jobCard:changesSaved')
          })
        }
        return true
      })
    },
    [bulkValues]
  )

  const showAlertConfirmDeleteJobs = () => {
    openAlert({
      className: 'w-[480px]',
      title: t('label:optionJobAction:deleteJob:title') || '',
      description: (
        <Trans
          i18nKey="job:bulkDeleteJobs"
          values={{
            number: bulkSelectedAll ? totalCount : bulkValues?.length
          }}>
          <span className="font-medium text-gray-900" />
        </Trans>
      ),
      actions: [
        {
          label: t('button:cancel') || '',
          type: 'secondary',
          size: 'sm'
        },
        {
          isCallAPI: true,
          label: t('button:delete') || '',
          type: 'destructive',
          size: 'sm',
          onClick: () => {
            onDeleteJobs()
          }
        }
      ]
    })
  }

  useEffect(() => {
    if (bulkSelectedAll) setIds()
  }, [jobs?.pages])

  useEffect(() => {
    return () => {
      // clear data when unmout
      resetBulkValues()
    }
  }, [])

  if (bulkSelectedAll || bulkValues?.length) {
    return (
      <BottomActionBar
        data={bulkValues || []}
        isSelectedAll={bulkSelectedAll || false}
        onClose={handleClose}
        setSelectedAll={handleCheckAll}
        onDelete={
          currentRole && adminCanAction(currentRole?.code)
            ? showAlertConfirmDeleteJobs
            : null
        }>
        <StatusSelector
          onSubmit={onUpdateStatuesJobs}
          totalCount={totalCount}
        />
      </BottomActionBar>
    )
  }

  return null
}

export default memo(BulkActions)
