import { useRouter } from 'next/router'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import pathConfiguration from 'src/configuration/path'
import configuration from '~/configuration'
import { IFormAction, IPromiseSearchOption } from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { Dialog } from '~/core/ui/Dialog'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import MutationCreateProfile from '~/lib/features/candidates/graphql/mutation-create-profile'
import QueryJobsApplicableList from '~/lib/features/candidates/graphql/query-applicable-job-list'
import QueryProfileShow from '~/lib/features/candidates/graphql/query-profile'
import schemaCreateProfile from '~/lib/features/candidates/schema/validation-create-profile'
import {
  AddCandidateFormType,
  ICandidateProfile
} from '~/lib/features/candidates/types'
import { descriptionLocationSelectJob } from '~/lib/features/jobs/utilities/common'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'
import CreateCandidateForm from './CreateCandidateForm'
import { ISelectOption } from '~/core/ui/Select'
import { JOB_DOT_STATUS } from '~/lib/features/jobs/utilities/enum'
import { IDotColorProps } from '~/core/ui/Dot'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import { AGENCY_TENANT, GLEAP_EVENTS } from '~/core/constants/enum'
import Gleap from 'gleap'
import useGleapCommon from '~/core/hooks/use-gleap-functions'

const CreateCandidateModal: FC<{
  open: boolean
  setOpen: (open: boolean) => void
  reload?: () => void
  defaultValue?: AddCandidateFormType
}> = ({ open, setOpen, reload, defaultValue }) => {
  const { t } = useTranslation()
  const user = useBoundStore((state) => state.user)
  const { trigger: createCandidate, isLoading } = useSubmitCommon(
    MutationCreateProfile
  )
  const setToast = useBoundStore((state) => state.setToast)
  const { clientGraphQL } = useContextGraphQL()
  const router = useRouter()
  const { isCompanyKind } = useDetectCompanyWithKind({ kind: AGENCY_TENANT })
  const { gleapTrackEvent4Submit } = useGleapCommon()
  const promiseJobListOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryJobsApplicableList, params)
        .toPromise()
        .then(
          (
            result: IResponseContextResult<{
              id: number
              title: string
              department: { name: string }
              company: { name: string }
              status?: string
              jobLocations: {
                state: string
                country: string
              }[]
            }>
          ) => {
            if (result.error) {
              resolve({
                metadata: {
                  totalCount: configuration.defaultAsyncLoadingOptions
                },
                collection: []
              })
            }

            const { jobsApplicableList } = result.data
            const collection = jobsApplicableList?.collection || []
            const metadata = jobsApplicableList?.metadata || {}

            const cloneData = collection.map(
              (item: {
                id: number
                title: string
                department: { name: string }
                company: { name: string }
                status?: string
                statusDescription?: string
                jobLocations: {
                  state: string
                  country: string
                }[]
              }) => {
                return {
                  value: item.id,
                  supportingObj: {
                    name: item.title || '',
                    shortName: item.title || '',
                    description: isCompanyKind
                      ? item?.company?.name
                      : item?.department?.name,
                    descriptionHelpName:
                      !!item.jobLocations?.[0]?.country &&
                      descriptionLocationSelectJob(item.jobLocations, t)
                  },
                  dot: item.status
                    ? (JOB_DOT_STATUS(item.status) as IDotColorProps)
                    : '',
                  dotDescription: item?.statusDescription
                }
              }
            )

            return resolve({ metadata, collection: cloneData })
          }
        )
    })

  const fetchProfileAndCheckProfile = useCallback(
    (email: string, formAction: IFormAction) => {
      return clientGraphQL
        .query(QueryProfileShow, {
          email
        })
        .toPromise()
        .then(
          (result: {
            error: { graphQLErrors: Array<object> }
            data: { profilesShow: ICandidateProfile }
          }) => {
            if (result.error) {
              return catchErrorFromGraphQL({
                error: result.error,
                setToast,
                router
              })
            }

            const { profilesShow } = result.data
            if (profilesShow?.id) {
              formAction.setError('email', {
                type: 'custom',
                message: `[${profilesShow?.id}, ${
                  profilesShow?.defaultAccessibleApplicantId || 0
                }]`
              })
              return
            }

            return
          }
        )
    },
    []
  )

  const onFinishCallback = useCallback(
    async (data: AddCandidateFormType, formAction: IFormAction) => {
      if (isLoading) {
        return
      }

      createCandidate({
        jobId: defaultValue?.jobId
          ? Number(defaultValue.jobId[0].value)
          : data.jobId?.length
          ? Number(data.jobId[0].value)
          : undefined,
        fullName: data.fullName,
        ...(data.email ? { email: data.email.trim() } : {}),
        ...(data.phoneNumber && data.phoneNumber !== data.countryCode?.dialCode
          ? { phoneNumber: data.phoneNumber.trim() }
          : {}),
        ...(data?.resumeFile && data?.resumeFile[0]
          ? { resumeFile: data?.resumeFile[0] }
          : {}),
        ...(data?.profileTalentPoolIds
          ? {
              profileTalentPoolIds: data?.profileTalentPoolIds?.map(
                (item: ISelectOption) => Number(item.value)
              )
            }
          : {})
      }).then((result) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            page: pathConfiguration.candidates.list,
            formAction,
            setToast,
            callbackHandleStatusError422: (keys) => {
              keys.forEach((session) => {
                if (session.field === 'email') {
                  if (data?.email) {
                    fetchProfileAndCheckProfile(data.email, formAction)
                  }
                } else {
                  if (
                    session.field &&
                    formAction.control._fields[session.field]
                  ) {
                    formAction.setError(session.field, {
                      type: 'custom',
                      message: String(session.message)
                    })
                  }
                }
              })
            }
          })
        }

        const { profilesCreate } = result.data
        if (profilesCreate?.profile?.id) {
          gleapTrackEvent4Submit(
            GLEAP_EVENTS.firstCandidateAdded,
            user.currentTenant?.interacted
          )

          reload && reload()
          setToast({
            open: true,
            type: 'success',
            title: `${t('candidates:CreateCandidateModal:success')}`,
            actions: [
              {
                label: `${t(
                  'candidates:CreateCandidateModal:buttonViewDetail'
                )}`,
                actionType: 'detail',
                callback: () => {
                  router.push(
                    configuration.path.candidates.detail(
                      Number(profilesCreate?.profile?.id),
                      profilesCreate?.profile?.applicants?.[0]?.id
                    )
                  )
                }
              }
            ]
          })
          setOpen(false)
        }
        return true
      })
    },
    [
      isLoading,
      createCandidate,
      defaultValue?.jobId,
      setToast,
      fetchProfileAndCheckProfile,
      reload,
      t,
      setOpen,
      router
    ]
  )

  return (
    <Dialog
      open={open}
      size="sm"
      onOpenChange={setOpen}
      isPreventAutoFocusDialog={true}
      label={`${t('candidates:CreateCandidateModal:title')}`}
      headingClassName="pb-4 tablet:pb-5">
      <DynamicImportForm
        isShowDebug={false}
        id="application-form"
        className="w-full"
        schema={schemaCreateProfile(t)}
        onSubmit={onFinishCallback}>
        {({ formState, control }) => {
          return (
            <CreateCandidateForm
              defaultValue={defaultValue}
              onOpenChange={setOpen}
              control={control}
              formState={formState}
              isLoading={isLoading}
              promiseJobListOptions={promiseJobListOptions}
            />
          )
        }}
      </DynamicImportForm>
    </Dialog>
  )
}

export default CreateCandidateModal
