import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMutation } from 'urql'
import { ISelectOption } from '~/core/ui/Select'
import { DeleteJobMutation } from '~/lib/features/jobs/graphql/delete-job-mutation'
import QueryCompanyJobList from '~/lib/features/jobs/graphql/query-company-job-list'
import QueryJobList from '~/lib/features/jobs/graphql/query-job-list'
import { ChangeJobStatusMutation } from '~/lib/features/jobs/graphql/submit-edit-job-mutation'
import { useInfinityGraphPage } from '~/lib/features/jobs/hooks/use-infinity-graph-page'
import {
  DEFAULT_SELECTED_JOB_STATUS_ROLE_MAP,
  JOB_STATUS_ENUM
} from '~/lib/features/jobs/utilities/enum'
import { trimObjectProps } from '~/lib/features/tasks/utilities/common'
import useBoundStore from '~/lib/store'
import { mappingFilterJob } from '../mapping/job-filter-mapping'
export interface IJobsManagementFilter {
  search?: string
  locationIds?: ISelectOption[]
  hiringMemberIds?: ISelectOption[]
  departmentIds?: Array<ISelectOption>
  status?: ISelectOption[]
  isFilterTouched?: boolean
  companyId?: ISelectOption
  location?: ISelectOption
  operator?: string
  tagIds?: ISelectOption[]
}

const useJobManagement = ({
  isCompanyKind,
  isLoaded,
  isActive = false
}: {
  isCompanyKind: boolean
  isLoaded: boolean
  isActive?: boolean
}) => {
  const { refetchMyDelete, currentRole } = useBoundStore()

  const [filterValue, onChangeFilter] = useState<
    IJobsManagementFilter | undefined
  >({
    status: DEFAULT_SELECTED_JOB_STATUS_ROLE_MAP[
      isActive ? 'defaultNoDraft' : currentRole?.code || 'default'
    ].map((status) => ({
      value: status,
      supportingObj: { name: '' }
    })),
    operator: 'or'
  })

  const jobPaging = useInfinityGraphPage({
    queryDocumentNote: isCompanyKind ? QueryCompanyJobList : QueryJobList,
    getVariable: useCallback(
      (page) => mappingFilterJob(page, filterValue || {}),
      [filterValue]
    ),
    getPageAttribute: (_lastGroup, groups) => ({
      totalCount: _lastGroup?.jobsList?.metadata?.totalCount,
      pageLength: groups?.[0]?.jobsList?.collection?.length
    }),
    queryKey: ['job-management-list'],
    enabled: false
  })

  useEffect(() => {
    if (isLoaded || refetchMyDelete) {
      jobPaging.refetch()
    }
  }, [filterValue, isLoaded, refetchMyDelete])

  const [{ fetching: deletingJob }, deleteJob] = useMutation(DeleteJobMutation)
  const [{ fetching: changingJobStatus }, changeJobStatus] = useMutation(
    ChangeJobStatusMutation
  )
  return {
    jobPaging,
    filterControl: useMemo(
      () => ({
        value: filterValue,
        onChange: onChangeFilter
      }),
      [filterValue]
    ),
    action: {
      jobDeleteAction: {
        deleteJob: (args: { id: number }) =>
          deleteJob(args).then(() => jobPaging.refetch()),
        deletingJob
      },
      changeJobStatusAction: {
        changeJobStatus: (args: { id: number; status: string }) =>
          changeJobStatus(args).then(() => jobPaging.refetch()),
        changingJobStatus
      }
    }
  }
}

export default useJobManagement
