import { IJobsManagementFilter } from '../hooks/use-job-management'
import { trimObjectProps } from '~/lib/features/tasks/utilities/common'
import { JOB_STATUS_ENUM } from '~/lib/features/jobs/utilities/enum'

export const mappingFilterJob = (
  page: number,
  pageParam: IJobsManagementFilter
) => {
  const {
    search,
    status,
    locationIds,
    departmentIds,
    hiringMemberIds,
    companyId,
    location,
    tagIds,
    operator
  } = pageParam

  return trimObjectProps({
    limit: 10,
    page,
    search,
    status:
      (status?.length || 0) > 0
        ? status?.map((status) => status.value)
        : undefined,
    countryStateIds:
      (locationIds?.length || 0) > 0
        ? locationIds?.map((item) => parseInt(item.value))
        : undefined,
    hiringMemberIds:
      (hiringMemberIds?.length || 0) > 0 &&
      hiringMemberIds?.map((item) => parseInt(item.value)),
    departmentIds:
      (departmentIds?.length || 0) > 0
        ? departmentIds?.map((item) => parseInt(item.value))
        : undefined,
    companyId: companyId?.value ? parseInt(companyId?.value) : undefined,
    ...(location?.value
      ? {
          country: location.value.split('*')[1],
          state: location.value.split('*')[0],
          countryStateId: Number(location.id)
        }
      : undefined),
    statusNot: [JOB_STATUS_ENUM.archived],
    tagIds: tagIds?.map((item) => parseInt(item.value)),
    operator: operator
  })
}
