export const AgencyMutationDeletesJobs = `
  mutation (
    $jobIds: [Int!]
		$selectAll: Boolean
    $search: String
    $companyId: Int
    $country: String
    $state: String
    $status: [JobStatus!]
    $hiringMemberIds: [Int!]
    $countryStateId: Int
    $tagIds: [Int!]
    $operator: String
  ) {
    jobsDeletes(
			input: { 
				jobIds: $jobIds,
				selectAll: $selectAll,
      	search: $search
				companyId: $companyId
				country: $country
				state: $state
				status: $status
				hiringMemberIds: $hiringMemberIds
				countryStateId: $countryStateId
				tagIds: $tagIds
				operator: $operator
			}
		) {
      success
    }
  }
`
