import Gleap from 'gleap'

const useGleapCommon = () => {
  const gleapTrackEvent4Submit = (eventKey: string, interacted?: boolean) => {
    if (Gleap && Gleap.getInstance().initialized === true && !interacted) {
      Gleap.trackEvent(eventKey)
    }
  }
  return {
    gleapTrackEvent4Submit
  }
}

export default useGleapCommon
